import jwt_decode from 'jwt-decode';
import { appInsights } from '../infrastructure/ApplicationInsights';

export abstract class UserFeature {

    // tslint:disable-next-line:no-any
    public static get UserId(): any {
        if (this.userId === '') {
            this.Init();
        }
        return this.userId;
    }

    // tslint:disable-next-line:no-any
    public static get Tenant(): any {
        if (this.tenantId === '') {
            this.Init();
        }
        return this.tenantId;
    }

    // tslint:disable-next-line:no-any
    private static userId: any = '';
    // tslint:disable-next-line:no-any
    private static tenantId: any = '';

    private static features: string[] = [];
    
    public static Init() {
        const accessToken = sessionStorage.getItem('accessToken');
        if (accessToken) {
            // tslint:disable-next-line:no-any
            let token: any = jwt_decode('' + accessToken);
            this.userId = token.sub;
            this.tenantId = token.tenantid;
            if (token.features) {
                this.features = token.features.split(',');
            }    
        }
    }

    public static Clear() {
        this.features = [];
        this.tenantId = '';
        this.userId = '';
    }

    public static HasAccess(userFeature: UserFeatureEnum): boolean {
        return this.checkAndTrackFeature(userFeature);
    }

    public static LogFeatureUsage(feature: string) {
        appInsights.trackEvent({ name: 'Feature Access', properties: { 
            tenantId : this.Tenant,
            userId: this.UserId,
            feature: feature
         } });
    }

    private static checkAndTrackFeature(feature: number): boolean {        
        if (this.features.length === 0) {
            this.Init();
        }
        var hasAccess = this.features.includes(feature.toString());
        if (hasAccess) {
            appInsights.trackEvent({ name: 'Feature Access', properties: { 
                tenantId : this.Tenant,
                userId: this.UserId,
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                feature: feature
             } });
        }
        return hasAccess;
    }    
}

export enum UserFeatureEnum {
    hasAdminDashboard = 1,
    hasBilledFeesVsEstimateTotalChart = 2,
    hasCriticalDateStream = 3,
    hasExpectedFutureFeesByMatterTypeChart = 4,
    hasExpectedFutureFeesByMatterCategoryChart = 5,
    hasExpectedFutureFeesByMatterCategoryAndTypeChart = 6,
    hasExpectedFutureFeesByResponsibleChart = 7,
    hasExpectedFutureFeesByActingChart = 8,
    hasExpectedFutureFeesByPropertyChart = 9,
    hasMatterActivityStream = 10,
    hasMatterCountByMatterTypeChart = 11,
    hasMatterCountByPropertyChart = 12,
    hasMatterCountByMatterCategoryChart = 13,
    hasMatterCountByMatterCategoryAndTypeChart = 14,
    hasMatterCountByResponsibleChart = 15,
    hasMatterCountByActingChart = 16,
    hasMatterDocument = 17,
    hasMattersByStatusChart = 18,
    hasMattersOpenedChart = 19,
    hasTotalOwingChart = 20,
    hasEstimatePerformanceChart = 21,
    hasUnbilledFeesByMatterTypeChart = 22,
    hasUnbilledFeesByPropertyChart = 23,
    hasUnbilledFeesByMatterCategoryChart = 24,
    hasUnbilledFeesByMatterCategoryAndTypeChart = 25,
    hasUnbilledFeesByResponsibleChart = 26,
    hasUnbilledFeesByActingChart = 27,
    hasUnbilledDisbursementsByMatterTypeChart = 28,
    hasUnbilledDisbursementsByPropertyChart = 29,
    hasUnbilledDisbursementsByMatterCategoryChart = 30,
    hasUnbilledDisbursementsByMatterCategoryAndTypeChart = 31,
    hasUnbilledDisbursementsByResponsibleChart = 32,
    hasUnbilledDisbursementsByActingChart = 33,
    hasDebtOwingByMatterTypeChart = 34,
    hasDebtOwingByPropertyChart = 35,
    hasDebtOwingByMatterCategoryChart = 36,
    hasDebtOwingByMatterCategoryAndTypeChart = 37,
    hasDebtOwingByResponsibleChart = 38,
    hasDebtOwingByActingChart = 39,
    hasMattersVsAverageChart = 40,
    hasMattersByPeriodByMatterTypeChart = 41,
    hasMattersByPeriodByMatterCategoryChart = 42,
    hasMattersByPeriodByMatterCategoryAndTypeChart = 43,
    hasMattersByPeriodByResponsibleChart = 44,
    hasMattersByPeriodByActingChart = 45,
    hasMattersByPeriodByPropertyChart = 46,
    hasMattersByPeriod3ByMatterTypeChart = 47,
    hasMattersByPeriod3ByMatterCategoryChart = 48,
    hasMattersByPeriod3ByMatterCategoryAndTypeChart = 49,
    hasMattersByPeriod3ByResponsibleChart = 50,
    hasMattersByPeriod3ByActingChart = 51,
    hasMattersByPeriod3ByPropertyChart = 52,
    hasMattersByPeriod7ByMatterTypeChart = 53,
    hasMattersByPeriod7ByMatterCategoryChart = 54,
    hasMattersByPeriod7ByMatterCategoryAndTypeChart = 55,
    hasMattersByPeriod7ByResponsibleChart = 56,
    hasMattersByPeriod7ByActingChart = 57,
    hasMattersByPeriod7ByPropertyChart = 58,
    hasAgedUnbilledFeesChart = 60,
    hasClosedMattersChart = 62,
    hasAverageBilledFeesClosedByMatterTypeChart = 63,
    hasAverageBilledFeesClosedByPropertyChart = 64,
    hasAverageBilledFeesClosedByMatterCategoryChart = 65,
    hasAverageBilledFeesClosedByMatterCategoryAndTypeChart = 66,
    hasAverageBilledFeesClosedByResponsibleChart = 67,
    hasAverageBilledFeesClosedByActingChart = 68,
    hasAverageFeeEstimateCurrentByMatterTypeChart = 69,
    hasAverageFeeEstimateCurrentByPropertyChart = 70,
    hasAverageFeeEstimateCurrentByMatterCategoryChart = 71,
    hasAverageFeeEstimateCurrentByMatterCategoryAndTypeChart = 72,
    hasAverageFeeEstimateCurrentByResponsibleChart = 73,
    hasAverageFeeEstimateCurrentByActingChart = 74,
    hasFeesVsBudgetByTimekeeperChart = 76,
    hasMatterSummary = 77,
    hasNameSummary = 78,
    hasStaffSummary = 79,
    hasTimesheet = 80,
    hasDashboardParts = 81,
    hasIPAttorneyDashboard = 82,
    hasLicencedReports = 83,
    hasReportCatalog = 84,
    hasBilledFeesVsEstimateByMatterTypeChart = 85,
    hasBilledFeesVsEstimateByPropertyChart = 86,
    hasBilledFeesVsEstimateByMatterCategoryChart = 87,
    hasBilledFeesVsEstimateByMatterCategoryAndTypeChart = 88,
    hasBilledFeesVsEstimateByResponsibleChart = 89,
    hasBilledFeesVsEstimateByActingChart = 90,
    hasMatterFeesVsEstimateChart = 92,
    hasInnographyLink = 93,
    hasClientSenseLink = 94,
    hasNewMattersInLastTwelveMonthsByMatterTypeChart = 95,
    hasNewMattersInLastTwelveMonthsByPropertyChart = 96,
    hasNewMattersInLastTwelveMonthsByMatterCategoryChart = 97,
    hasNewMattersInLastTwelveMonthsByMatterCategoryAndTypeChart = 98,
    hasNewMattersInLastTwelveMonthsByResponsibleChart = 99,
    hasNewMattersInLastTwelveMonthsByActingChart = 100,
    hasDashboardConfiguration = 101,
    hasDashboardSecurityScope = 102,
    hasDashboardSecurityRoleCategory = 103,
    hasDashboardSecurityRole = 104,
    hasDashboardSecurityUser = 105,
    hasDashboardSecurityPersonal = 106,
    hasConfigurationMaintenance = 107,
    hasFeatureLicencingConfiguration = 108,
    hasFeatureAccessConfiguration = 109,
    hasFeatureAccessSecurityFirm = 110,
    hasFeatureAccessSecurityCategory = 111,
    hasFeatureAccessSecurityRole = 112,
    hasFeatureAccessSecurityUser = 113,
    hasFeatureAccessSecurityPersonal = 114,
    hasRoleConfiguration = 115,
    hasLeads = 116,
    hasLeadsSummary = 117,
    hasLeadsDashboard = 118,
    hasTimesheetTimer = 119,
    hasTimesheetSummary = 120,
    hasTimesheetUpdate = 121,
    hasLeadMaintenance = 122,
    hasCreateLead = 123,
    hasModifyLead = 124,
    hasDeleteLead = 125,
    hasAssignLead = 126,
    hasFollowUpLead = 127,
    hasNewLeadsInLast12MonthsTotal = 128,
    hasNewLeadsInLast12MonthsBySource = 129,
    hasNewLeadsInLast12MonthsByAreaOfLaw = 130,
    hasAverageDaysLeadFollowUpByLawyer = 131,
    hasLeadConversionInLast12MonthsByLawyer = 132,
    hasLeadsNotProceedingInLast12MonthsByReason = 133,
    hasBulkMatterManagement = 134,
    hasMatterTasks = 135,
    hasWriteOffWIP = 136,
    hasWriteOffDebtors = 137,
    hasBillMatter = 138,
    hasTransferMatterTrust = 139,
    hasArchiveMatter = 140,
    hasCloseMatter = 141,
    hasEmailLeadAssignee = 142,
    hasLeadsDetail = 143,
    hasLookupConfiguration = 144,
    hasPracticeAreaLookupMaintenance = 145,
    hasLeadSourceLookupMaintenance = 146,
    hasLeadReasonLookupMaintenance = 147,
    hasLeadFollowUpActionMaintenance = 148,
    hasTasks = 149,
    hasTaskSummary = 150,
    hasTaskDetail = 151,
    hasAllocateTask = 152,
    hasTaskMaintenance = 153,
    hasDeleteTask = 154,
    hasModifyTask = 155,
    hasCreateTask = 156,
    hasUserAdmininstration = 157,
    hasTimelinesAdmininstration = 158,
    hasMatterAccessAdmininstration = 159,
    hasMessagesAdmininstration = 160,
    hasMattersAdmininstration = 161,
    hasDocumentsAdmininstration = 162,
    hasReportsAdmininstration = 163,
    hasNameAccessAdmininstration = 164,
    hasLoginAccessAdmininstration = 165,
    hasTaskCategoryLookupMaintenance = 166,
    hasPriorityLookupMaintenance = 167,
    hasSplashScreenConfiguration = 168,
    hasLocationLookupMaintenance = 169,
    hasFirmDashboard = 170,
    hasFeeEarnerDashboard = 171,
    hasFirmDashboardConfiguration = 172,
    hasFeeEarnerDashboardConfiguration = 173,
    hasLeadsDashboardConfiguration = 174,
    hasFirmOfficeLookupMaintenance  = 175,
    hasFirmDepartmentLookupMaintenance = 176,
    hasFirmTeamLookupMaintenance = 177,
    hasEmailTemplateConfiguration = 178,
    hasFirmDashboardFirmView = 179,
    hasFirmDashboardOfficeView = 180,
    hasFirmDashboardDepartmentView = 181,
    hasFirmDashboardTeamView = 182,
    hasFirmDashboardFeeEarnerView = 183,
    hasMatterSummaryViewManagement = 184,
    hasHelp = 185,
    hasUserDefinedHelp = 186,
    hasUserDefinedHelpConfiguration = 187,
    hasFirmDashboardReportingGroupView = 188,
    hasCreateClient = 189, 
    hasFeesBilledInPast14DaysChart = 191,
    hasFeesBilledInLast12MonthsChart = 192,
    hasFeesBilledMonthToDateByActingChart = 193,
    hasFeesBilledMonthToDateByResponsibleChart = 194,
    hasFeesBilledMonthToDateByMatterTypeChart = 195,
    hasFeesBilledMonthToDateByMatterCategoryChart = 196,
    hasFeesBilledMonthToDateByPropertyTypeChart = 197,
    hasFeesBilledMonthToDateByReportingGroupChart = 198,
    hasFeesBilledInLastYearByActingChart = 199,
    hasFeesBilledInLastYearByResponsibleChart = 200,
    hasFeesBilledInLastYearByMatterTypeChart = 201,
    hasFeesBilledInLastYearByMatterCategoryChart = 202,
    hasFeesBilledInLastYearByPropertyTypeChart = 203,
    hasFeesBilledInLastYearByReportingGroupChart = 204,
    hasInvoicesInDraftByActingChart = 205,
    hasInvoicesInDraftByResponsibleChart = 206,
    hasInvoicesInDraftByMatterTypeChart = 207,
    hasInvoicesInDraftByMatterCategoryChart = 208,
    hasInvoicesInDraftByPropertyTypeChart = 209,
    hasInvoicesInDraftByReportingGroupChart = 210,
    hasReferrers = 211,
    hasReferrerSummary = 212,
    hasReferrerMaintenance = 213,
    hasReferrerBackgroundMaintenance = 214,
    hasReferrerDetailMaintenance = 215,
    hasEmailTemplateConfigurationPage = 216,
    hasEmailTemplateSecurityScope = 217,
    hasEmailTemplateSecurityRoleCategory = 218,
    hasEmailTemplateSecurityRole = 219,
    hasEmailTemplateSecurityUser = 220,
    hasSplashScreenConfigurationPage = 221,
    hasSplashScreenSecurityScope = 222,
    hasSplashScreenSecurityRoleCategory = 223,
    hasSplashScreenSecurityRole = 224,
    hasSplashScreenSecurityUser = 225,
    hasUserDefinedHelpConfigurationPage = 226,
    hasUserDefinedHelpSecurityScope = 227,
    hasUserDefinedHelpSecurityRoleCategory = 228,
    hasUserDefinedHelpSecurityRole = 229,
    hasUserDefinedHelpSecurityUser = 230,
    hasAdminDashboardPage = 231,
    hasMatterActivityChart = 232,
    hasMattersWithoutTrustByMatterTypeChart = 233,
    hasMattersWithoutTrustByMatterCategoryChart = 234,
    hasMattersWithoutTrustByMatterCategoryAndTypeChart = 235,
    hasMattersWithoutTrustByResponsibleChart = 236,
    hasMattersWithoutTrustByActingChart = 237,
    hasMattersWithoutTrustByPropertyChart = 238,
    hasPipelineVsMatterActivityChart = 239,
    hasReferrerSourceLookupMaintenance = 240,
    hasReferrerTypeLookupMaintenance = 241,
    hasRelationshipQualityLookupMaintenance = 242,
    hasNewLeadsInLast30DaysBySource = 243,
    hasNewLeadsInLast30DaysByAreaOfLaw = 244,
    hasNewLeadsInLast90DaysBySource = 245,
    hasNewLeadsInLast90DaysByAreaOfLaw = 246,
    hasMailingList = 247,
    hasMatterEmailPersonActing = 248,
    hasMatterEmailPersonResponsible = 249,
    hasViewMatterTask = 250,
    hasDeleteMatterTask = 251,
    hasNameMaintenance = 252,
    hasUploadMatterDocument = 253,
    hasUserOptionsConfiguration = 254,
    hasUserAssistantLookupMaintenance = 255,
    hasDebtOwingByClientChart = 256,
    hasClientExposureChart = 257,
    hasClientContactTagLookupMaintenance = 258,
    hasDashboardPanelLookupMaintenance = 259,
    hasMatterFollowUpActionLookupMaintenance = 260,
    hasFollowUpMatterTask = 261,
    hasLeadConversionInLast30DaysByLawyer = 262,
    hasLeadsNotProceedingInLast30DaysByReason = 263,
    hasLeadConversionInLast90DaysByLawyer = 264,
    hasLeadsNotProceedingInLast90DaysByReason = 265,
    hasFeesBilledInLastFinancialYearChart = 266,
    hasFeesBilledForMonthChart = 267,
    hasMailingClientAndContact = 268,
    hasMailingReferrers = 269,
    hasMailingMatters = 270,
    hasMailingNewBusinessEnquiries = 271,
    hasTopReferrersInLastThirtyDaysChart = 272,
    hasTopReferrersInLastNinetyDaysChart = 273,
    hasTopReferrersInLast12MonthsChart = 274,
    hasEnquiryViewAll = 275,
    hasEnquiryViewMyDepartmentOnly = 276,
    hasEnquiryViewMyOfficeOnly = 277,
    hasEnquiryViewMyTeamOnly = 278,
    hasEnquiryViewMyEnquiriesOnly = 279,
    hasTimesheetTimeAndFeesInLastPeriodsChart = 280,
    hasTimesheetFeesInLastPeriodsChart = 281,
    hasTimesheetTimeInLastPeriodsChart = 282,
    hasTimesheetTimeInFinancialYearChart = 283,
    hasTimesheetFeesInFinancialYearChart = 284,
    hasFirmDashboardMyOfficeOnly = 285,
    hasFirmDashboardMyDepartmentOnly = 286,
    hasFirmDashboardMyTeamOnly = 287,
    hasClientNotes = 288,
    hasMatterNotes = 289,
    hasTaskViewAll = 290,
    hasTaskViewMyDepartmentOnly = 291,
    hasTaskViewMyOfficeOnly = 292,
    hasTaskViewMyTeamOnly = 293,
    hasViewMatterDocuments = 294, 
    hasFirmStructure = 295,
    hasUserDashboard = 296,
    hasEnquirySummaryViewManagement = 297,
    hasNameSummaryViewManagement = 298,
    hasReferrerSummaryViewManagement = 299,
    hasTimesheetTimeInFinancialYearAggregateChart = 300,
    hasTimesheetFeesInFinancialYearAggregateChart = 301,
    hasFeesBilledChart = 302,
    hasFeesBilledVsBudgetChart = 303,
    hasFeesBilledCumulativeChart = 304,
    hasFeesBilledCumulativeVsBudgetChart = 305,
    hasFeesBilledByPeriodChart = 306,
    hasFeesBilledByPeriodVsBudgetChart = 307,
    hasFeesCollectedChart = 308,
    hasFeesCollectedVsBudgetChart = 309,
    hasFeesCollectedCumulativeChart = 310,
    hasFeesCollectedCumulativeVsBudgetChart = 311,
    hasFeesCollectedByPeriodChart = 312,
    hasFeesCollectedByPeriodVsBudgetChart = 313,
    hasStaffBillingSummaryViewManagement = 314,
    hasStaffBudgetCollectionsView = 315,
    hasStaffBudgetCollectionsEdit = 316,
    hasStaffBudgetBillingsView = 317,
    hasStaffBudgetBillingsEdit = 318,
    hasStaffBudgetProductionView = 319,
    hasStaffBudgetProductionEdit = 320,
    hasStaffSummaryViewManagement = 321,
    hasNewLeadsInLast7DaysBySource = 322,
    hasNewLeadsInLast7DaysByAreaOfLaw = 323,
    hasMarkAsClientCreated = 324, 
    hasKeyOpportunity = 325, 
    hasTimesheetFeesWithBudgetInLastPeriodsChart = 326,
    hasTimesheetTimeWithBudgetInLastPeriodsChart = 327,
    hasTimesheetTimeWithBudgetInFinancialYearChart = 328,
    hasTimesheetFeesWithBudgetInFinancialYearChart = 329,
    hasTimesheetTimeWithBudgetInFinancialYearAggregateChart = 330,
    hasTimesheetFeesWithBudgetInFinancialYearAggregateChart = 331,
    hasPersonaliseFirmDashboardConfiguration = 332,
    hasPersonaliseFeeEarnerDashboardConfiguration = 333,
    hasPersonaliseLeadsDashboardConfiguration = 334,
    hasAdHocTask = 335,
    hasPhoneTask = 336,
    hasIntegration = 337,
    hasVisionSix = 338,
    hasEnquiryEmailFollowUp = 339,
    hasEnquiryBookMeeting = 340,
    hasIntegrationConfiguration = 341,
    hasFirmSettingsConfiguration = 342,
    hasTimesheetViewAll = 343,
    hasTimesheetViewMyDepartmentOnly = 344,
    hasTimesheetViewMyOfficeOnly = 345,
    hasTimesheetViewMyTeamOnly = 346,
    hasTaskTypeMaintenance = 347,
    hasTaskTypeGroupMaintenance = 348,
    hasPoBoxTypeLookupMaintenance = 349,
    hasDebtCollection = 350,
    hasMatterDebtCollectionDetails = 351,
    hasBillingRealisationChart = 352,
    hasStaffMaintenance = 353,
    hasDebtorDaysByPersonActingChart = 354,
    hasDebtorDaysByPersonResponsibleChart = 355,
    hasDebtorDaysByMatterCategoryChart = 356,
    hasDebtorDaysByMatterTypeChart = 357,
    hasWIPLockUpDaysByPersonActingChart = 358,
    hasWIPLockUpDaysByPersonResponsibleChart = 359,
    hasWIPLockUpDaysByMatterCategoryChart = 360,
    hasWIPLockUpDaysByMatterTypeChart = 361,
    hasFutureSettlementsChart = 362,
    hasMatterDurationByCategoryOrTypeChart = 363,
    hasMatterCountByReportingGroupChart = 364,
    hasSalesPipelineByAreaOfLawChart = 365,
    hasSalesPipelineByAssigneeChart = 366,
    hasSalesPipelineByLocationChart = 367,
    hasEnquiriesByQualityChart = 368,
    hasEnquiriesByLikelihoodToProceedChart = 369,
    hasHoursRecordedByStaffMembersChart = 370,
    hasCollectionRateChart = 371,
    hasDueDatesByPersonChart = 372,
    hasDueDatesOverviewChart = 373,
    hasTrustBalanceChart = 374,
    hasClientBillingChart = 375,
    hasNonBillableTimesByActivityChart = 376,
    hasNonBillableTimesByStaffChart = 377,
    hasMatterBillingChart = 378,
    hasFeeEarnerDashboardViewAll = 379,
    hasFeeEarnerDashboardViewMyDepartmentOnly = 380,
    hasFeeEarnerDashboardViewMyOfficeOnly = 381,
    hasFeeEarnerDashboardViewMyTeamOnly = 382,
    hasShowFeeEarnerDashboard = 383,
    hasYearBudgetsChart = 384,
    hasTaskQueueByAssigneeChart = 385,
    hasTaskDashboardFirmView = 386,
    hasTaskDashboardOfficeView = 387,
    hasTaskDashboardDepartmentView = 388,
    hasTaskDashboardTeamView = 389,
    hasTaskDashboardFeeEarnerView = 390,
    hasTaskDashboardMyOfficeOnly = 391,
    hasTaskDashboardMyDepartmentOnly = 392,
    hasTaskDashboardMyTeamOnly = 393,
    hasTaskDashboard = 394,
    hasTaskDashboardConfiguration = 395,
    hasCostAgreementChart = 396,
    hasTaskQueueByCreationTimeChart = 397,
    hasTaskQueueByTaskTypeDescriptionChart = 398,
    hasBrandsConfiguration = 399,
    hasFutureFeesChart = 400,
    hasNewMattersInLastTwelveMonthsByReportingGroup = 401,
    hasClosedMattersByFeeEarnerChart = 403,
    hasFeesBilledVsBudgetByFeeEarnerChart = 402,
    hasCreateRecord = 404,
    hasUpdateRecord = 405,
    hasDeleteRecord = 406,
    hasEventsAndCampaignsSummary = 407,
    hasCreateEventsAndCampaigns = 408,
    hasUpdateEventsAndCampaigns = 409,
    hasDeleteEventsAndCampaigns = 410,
    hasTrustFundsAvailableChart = 411,
    hasCombinedNewMattersByCategoryChart = 412,
    hasScrubData = 413,
    hasClientDashboard = 414,
    hasClientDashboardConfiguration = 415,
    hasMatterLearnMore = 416,
    hasClientDashboardPropertyFilter = 417,
    hasClientDashboardCountryFilter = 418,
    hasClientDashboardBrandFilter = 419,
    hasClientDashboardCategoryFilter = 420,
    hasClientDashboardTypeFilter = 421,
    hasClientDashboardStatusFilter = 422,
    hasMattersByCountryChart = 423,
    hasRiskLevelLookupMaintenance = 424,
    hasGeneralMaterial = 425,
    hasGeneralMaterialConfiguration = 426,
    hasFutureSettlementsByPriceChart = 427,
    hasCurrentMattersByAustralianStateChart = 428,
    hasEventStatusLookupMaintenance = 429,
    hasEventTypeLookupMaintenance = 430,
    hasCorrespondenceActivityLookupMaintenance = 431,
    hasCurrentMattersByUSStateChart = 433,
    hasMatterFinancial  = 434,
    hasMatterUnbilledTimeAndDisbursements = 435,
    hasMatterWIPInformation = 436,
    hasBookMeetingEmailPreview = 437,
    hasRoleSecurityConfiguration = 438,
    hasOtherPartyTypeLookupMaintenance = 439,
    hasNameDocument = 440,
    hasClientDocuments = 441,
    hasTrustBalancesByAgeChart = 442,
    hasMatterExport = 443,
    hasClientAndContactExport = 444,
    hasSummaryColumnSecurity = 445,
    hasFeesBilledVsBudgetSpeedometerChart = 446,
    hasTotalDebtOwingPieChart = 447,
    hasBillableTimesByStaffChart = 448,
    hasTaskQueueByPriorityChart = 449,
    hasFeesRecordedVsBudgetByFeeEarnerChart = 450,
    hasFeesBilledVsBudgetByOfficeChart = 451,
    hasFeesBilledVsBudgetByDepartmentChart = 452,
    hasFeesBilledVsBudgetByTeamChart = 453,
    hasFeeEstimateWarning = 454,
    hasClientAndContactNotes = 455,
    hasNotesSummary = 456,
    hasNotesSummaryViewManagement = 457,
    hasClientAndMatterNoteSubjectLookupMaintenance = 458,
    hasBillableHoursRecordedVsBudget = 459,
    hasClientAndContactDashboard = 460,
    hasCreateAttendee  = 461,
    hasClientAndContactFinancials = 462,
    hasEmailSendOrReplyDefinition = 463,
    hasClientNotesManagement = 464,
    hasClientNotesFollowUp = 465,
    hasKpiSummaryChart = 466,
    hasCumulativeBillableHoursVsBudgetChart = 467,
    hasLeapTask = 468,
    hasMatterForecast = 469,
    hasForecastFeesBilledChart = 470,
    hasForecastFeesCollectedChart = 471,
    hasForecastHoursChart = 472,
    hasForecastFeesBilledByCategoryChart = 473,
    hasForecastFeesCollectedByCategoryChart = 474,
    hasForecastHoursByCategoryChart = 475,
    hasNewLeadsInLast30DaysByAssignee = 476,
    hasBillableHoursRecordedVsBudgetSpeedometerChart = 477,
    hasFeesRecordedVsBudgetSpeedometerChart = 478,
    hasFeesCollectedVsBudgetSpeedometerChart = 479,
    hasUploadClientAndContactImage = 483,
    hasDeleteClientAndContactImage = 484,
    hasArtificialIntelligence = 485,
    hasEmailTemplateAI = 486,
    hasPhoneCallResponseTimeChart = 487,
    hasTaskResponseTimeByAssignee = 488,
    hasTaskResponseTimeByTaskType = 489,
    hasAverageElapsedTimeByTaskType = 490,
    hasCRMContactProfessionalBackgroundAI = 491,
    hasFinancialYearBudgetForecastChart = 492,
    hasEnquiryHealthChart = 493,
    hasEnquiriesCustomFields = 494,
    hasCustomFieldMaintenance = 495,
    hasEnquiryCustomFieldsConfiguration = 496,
    hasAverageElapsedTimeByAssignee = 497,
    hasClientAndContactRelationshipData = 508,
    hasClientAndContactActivityDashboardCategory = 509,
    hasContactActivitiesByStaff = 510,
    hasContactActivitiesBySubject = 511
}
